dmx.Component('link', {

    initialData: {
        active: false
    },

    tag: 'a',

    attributes: {
        href: {
            type: String,
            default: ''
        },

        internal: {
            type: Boolean,
            default: false
        }
    },

    render: function(node) {
        dmx.BaseComponent.prototype.render.call(this, node);
        this.$node.addEventListener('click', this.navigate.bind(this));
        this.routeLink = false;
        this.update({});
    },

    update: function(props) {
        if (props.href != this.props.href) {
            var url = this.props.href;

            if (dmx.routing.router != 'hybrid') {
                if ((url.startsWith('/') && !url.startsWith('//')) || url.startsWith('#')) {
                    this.routeLink = true;
                }

                if (url.startsWith('./')) {
                    var parent = this.parent;
            
                    while (parent) {
                        if (parent.routes && parent.path) {
                            url = url.replace('./', parent.path);
                            break;
                        }

                        parent = parent.parent;
                    }

                    url = url.replace('./', '/');
                    this.routeLink = true;
                }

                if (url.startsWith('~/')) {
                    url = url.replace('~/', '/');
                    this.routeLink = true;
                }

                if (dmx.routing.router == 'path' && !url.startsWith('#')) {
                    url = dmx.routing.join(dmx.routing.getBase(), url);
                }
            } else {
                if (url[0] == '.' && url[1] == '/') {
                    var parent = this.parent;
                    while (parent) {
                        if (parent.routes && parent.path) {
                            url = dmx.routing.join('./', url.replace('./', parent.path));
                            break;
                        }

                        parent = parent.parent;
                    }

                    var route = document.querySelector('meta[name="ac:route"]');
                    if (route && route.content) {
                        var path = route.content;
                        
                        var base = document.querySelector('meta[name="ac:base"]');
                        if (base && base.content) {
                            path = base.content.replace(/\/$/, '') + path;
                        }

                        var match = dmx.pathToRegexp(path, [], { end: false }).exec(location.pathname);

                        if (match) {
                            this.$node.setAttribute('href', url.replace('./', match[0].replace(/(\/+)?$/, '/')));
                            this.routeLink = true;
                            return;
                        }
                    } else {
                        this.$node.setAttribute('href', dmx.routing.join(dmx.routing.getBase(), url));
                        this.routeLink = true;
                        return;
                    }
                }

                if (url[0] == '~' && url[1] == '/') {
                    var route = document.querySelector('meta[name="ac:route"]');
                    if (route && route.content) {
                        var path = route.content;
                        
                        var base = document.querySelector('meta[name="ac:base"]');
                        if (base && base.content) {
                            path = base.content.replace(/\/$/, '') + path;
                        }

                        var match = dmx.pathToRegexp(path, [], { end: false }).exec(location.pathname);

                        if (match) {
                            this.$node.setAttribute('href', url.replace('~/', match[0].replace(/(\/+)?$/, '/')));
                            this.routeLink = true;
                            return;
                        }
                    } else {
                        this.$node.setAttribute('href', dmx.routing.join(dmx.routing.getBase(), url.slice(1)));
                        this.routeLink = true;
                        return;
                    }
                }

                if (url[0] == '#') {
                    this.routeLink = true;
                } else {
                    this.routeLink = false;
                }
            }

            this.$node.setAttribute('href', url);
        }

        this.set('active', this.isActive());
    },

    isActive: function() {
        try {
            var url = dmx.routing.getUrlInfo();
            var href = new URL(this.$node.href);
            return href.pathname == url.path;
        } catch (e) {
            return false;
        }
    },

    navigate: function(event) {
        if ((this.routeLink || this.props.internal) && !event.ctrlKey && event.button == 0) {
            event.preventDefault();

            var url = this.$node.getAttribute('href');

            if (dmx.routing.router != 'hybrid') {
                window.history.pushState({ title: this.$node.title || document.title }, '', dmx.routing.router == 'hash' ? '#!' + url : url);
            } else {
                if (url[0] == '#') {
                    window.location.hash = url;
                    return;
                }

                window.history.pushState({ title: this.$node.title || document.title }, '', url);
                if (this.$node.title) document.title = this.$node.title;
            }

            dmx.requestUpdate();

            var pushStateEvent = document.createEvent('Event');
            pushStateEvent.initEvent('pushstate', false, false);
            window.dispatchEvent(pushStateEvent);
        }
    }

});
