dmx.routing = {
    router: 'hybrid', // hybrid (combined server/client routing), path or hash

    base: '', // todo: use when router is path (when page is in a subfolder)

    routes: [],

    getRoutes: function() {
        return this.routes.filter(function(route) {
            return !route.app || route.app == dmx.app.name;
        });
    },
    
    getBase: function() {
        if (this.base) {
            return this.base;
        } else {
            var base = document.querySelector('base[href]');
            if (base) return base.getAttribute('href');
        }

        return '';
    },

    getUrlInfo: function() {
        var url = this.router == 'hash' ? new URL(window.location.hash.slice(2), window.location.origin) : window.location;

        return {
            path: url.pathname || '/',
            query: url.search.slice(1),
            hash: url.hash.slice(1)
        };
    },

    match: function(path, routes, parent) {
        path = path || this.getUrlInfo().path;
        routes = routes || this.getRoutes();
        
        var base = dmx.routing.getBase();
        if (base) {
            path = path.replace(base, '').replace(/^\/?/, '/');
        }

        for (var i = 0; i < routes.length; i++) {
            if (routes[i].routes) {
                routes[i].end = false;
            }

            var keys = [];
            var re = dmx.pathToRegexp(dmx.routing.join(parent && parent.path ? parent.path : '/', routes[i].path), keys, routes[i]);
            var match = re.exec(decodeURI(path));

            if (match) {
                return {
                    path: match[0],
                    params: keys.reduce(function(params, key, index) {
                        params[key.name] = match[index + 1];
                        return params;
                    }, {}),
                    url: routes[i].url,
                    routes: routes[i].routes || []
                };
            }
        }

        return null;
    },

    join: function(path1, path2) {
        return path1.replace(/\/$/, '') + '/' + path2.replace(/^\//, '');
    }
};